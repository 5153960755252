import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import NavLinks from "./NavLinks";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import logo from "../../assets/logo.png";
import bgphoto from "../../assets/bgphoto.png"; 
import LogInPopUp from "../LogInPopUp.jsx";
import "../App.css";

const theme = createTheme();

const NavBar = ({ isHomePage }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const isSmallOrMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openPopUp, setOpenPopUp] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenPopUp = () => {
    setOpenPopUp(true);
  };

  const handleClosePopUp = () => {
    setOpenPopUp(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const LogoComponent = () => (
    isHomePage ? (
      <ScrollLink
        to="home"
        className="nav-link"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            maxWidth: "100px",
            height: "auto",
            padding: "10px 0",
          }}
        />
      </ScrollLink>
    ) : (
      <RouterLink to="/" className="nav-link" onClick={scrollToTop}>
        <img
          src={logo}
          alt="Logo"
          style={{
            maxWidth: "100px",
            height: "auto",
            padding: "10px 0",
          }}
        />
      </RouterLink>
    )
  );

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed" style={{ backgroundColor: "#080667" }}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-evenly" fontWeight="bold">
            <Grid item xs={isSmallOrMediumScreen ? 6 : 2}>
              <LogoComponent />
            </Grid>
            {!isSmallOrMediumScreen && (
              <Grid item xs={7} container >
                <NavLinks isHomePage={isHomePage} />
              </Grid>
            )}
            <Grid item xs={isSmallOrMediumScreen ? 5 : 3} container justifyContent="flex-end">
              {isSmallOrMediumScreen ? (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <>
                  <Button
                    className="enrollButtons"
                    variant="contained"
                    sx={{
                      background: "linear-gradient( 90deg, #3200C1,#B80EE3 )",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginRight: 2,
                    }}
                    onClick={handleOpenPopUp}
                  >
                    Log In
                  </Button>
                  <Button
                    className="options"
                    variant="outlined"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      border: "none",
                    }}
                    component={RouterLink}
                    to="/register"
                  >
                    Register
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {isSmallOrMediumScreen && (
        <Drawer 
          anchor="right" 
          open={drawerOpen} 
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              backgroundImage: `url(${bgphoto})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
          }}
        >
          <List sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <ListItem sx={{ flex: 1 }}>
              <NavLinks isHomePage={isHomePage} />
            </ListItem>
            <ListItem>
              <Button
                fullWidth
                className="enrollButtons"
                variant="contained"
                sx={{
                  background: "linear-gradient( 90deg, #3200C1,#B80EE3 )",
                  color: "#ffffff",
                  fontWeight: "bold",
                  marginBottom: 2,
                }}
                onClick={() => {
                  handleOpenPopUp();
                  toggleDrawer(false)();
                }}
              >
                Log In
              </Button>
            </ListItem>
            <ListItem>
              <Button
                fullWidth
                className="options"
                variant="outlined"
                style={{
                  color: "#080667",
                  fontWeight: "bold",
                  border: "1px solid #080667",
                }}
                component={RouterLink}
                to="/register"
                onClick={toggleDrawer(false)}
              >
                Register
              </Button>
            </ListItem>
          </List>
        </Drawer>
      )}

      <LogInPopUp open={openPopUp} handleClose={handleClosePopUp} />
    </ThemeProvider>
  );
};

export default NavBar;