import React, { useEffect, useState, useRef } from "react";
import { Typography, Button, Card, CardContent, TextField, useTheme, useMediaQuery } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import './App.css';
import bgphoto from "../assets/bgphoto.png";
import testimonialsimg from '../assets/testimonialsblock.png';

const FAQItem = ({ question, answer }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="container" id="faqs">
      <div className="question-container" onClick={toggleDropdown}>
        <Typography
          variant="body1"
          style={{
            color: "#FFFFFF",
            fontFamily: "Spartan, sans-serif",
            fontSize: isSmallScreen ? "14px" : isMediumScreen ? "16px" : "18px",
            textAlign: "left",
            fontWeight: "bold",
            paddingLeft: "10px",
          }}
        >
          {question}
        </Typography>
        <Button>
          {isDropdownOpen ? (
            <ArrowDropUpIcon style={{ color: "#FFFFFF" }} />
          ) : (
            <ArrowDropDownIcon style={{ color: "#FFFFFF" }} />
          )}
        </Button>
      </div>
      <div className={`answer ${isDropdownOpen ? 'open' : ''}`}>
        <Typography variant="body1" style={{ fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "16px" }}>
          {answer}
        </Typography>
      </div>
    </div>
  );
};

const FAQs = () => {
  const faqsRef = useRef(null);
  const contactRef = useRef(null);
  const faqItems = [
    {
      question: "What Is SkillsCraze?",
      answer: "SkillsCraze is an Ed-tech platform. We help you learn digital creative skills and monetize them while learning.",
    },
    {
      question: "Our Mission?",
      answer: "To create a maximum number of digital entrepreneurs and to help them achieve financial freedom.",
    },
    {
      question: "Why Choose SkillsCraze?",
      answer: "SkillsCraze is the best platform because we provide both learning and mentorship.",
    },
    {
      question: "How Can I Join?",
      answer: "Just choose any package, buy it, and you're done. Now you can Learn and Grow.",
    },
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isVisibleFAQs, setIsVisibleFAQs] = useState(false);
  const [isVisibleContact, setIsVisibleContact] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (faqsRef.current) {
        const faqsTop = faqsRef.current.getBoundingClientRect().top;
        setIsVisibleFAQs(faqsTop < window.innerHeight - 100);
      }

      if (contactRef.current) {
        const contactTop = contactRef.current.getBoundingClientRect().top;
        const contactBottom = contactRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
        setIsVisibleContact(contactTop < windowHeight && contactBottom > 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      style={{
         backgroundImage: `url(${bgphoto})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        minHeight: "100vh",
        overflowX: "hidden",
        boxSizing: "border-box",
        paddingBottom: isSmallScreen ? "50px" : "300px",
        display: "flex",
        flexDirection: isSmallScreen || isMediumScreen ? "column" : "row",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "100%",
        padding: isSmallScreen ? "20px" : isMediumScreen ? "30px" : "40px",
      }}
    >
      {/* FAQs Section */}
      <div
        ref={faqsRef}
        className={`${isVisibleFAQs ? "slide-in-from-left visible" : ""}`}
        style={{ 
          width: isSmallScreen ? "100%" : isMediumScreen ? "90%" : "47%",
          padding: isSmallScreen ? "20px 0" : "30px 0",
          maxWidth: isSmallScreen ? "100%" : "720px",
          boxSizing: "border-box",
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "#BABABA",
            fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "15px",
            fontWeight: "bold",
            fontFamily: "Poppins, sans-serif",
            margin: "0 0 14px",
          }}
        >
          FAQ's
        </Typography>
        <Typography
          variant="h3"
          style={{
            color: "#FFFFFF",
            fontFamily: "Spartan, sans-serif",
            fontSize: isSmallScreen ? "20px" : isMediumScreen ? "24px" : "30px",
            fontWeight: "bold",
            margin: "25px 4px",
          }}
        >
          Learn Your Best Education Culture with SkillsCraze
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: "#BABABA",
            fontFamily: "Poppins, sans-serif",
            fontSize: isSmallScreen ? "14px" : isMediumScreen ? "15px" : "17px",
            margin: "15px 0 24px",
            fontWeight: "bold",
          }}
        >
          We have the best support system for the Genius Empire family. It's 24×7 open for everyone - any time
        </Typography>
        {faqItems.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
      {/* Contact Section */}
      <div id="contact" style={{ 
        width: isSmallScreen ? "100%" : isMediumScreen ? "90%" : "47%",
        maxWidth: "500px",
        boxSizing: "border-box",
        marginTop: isSmallScreen || isMediumScreen ? "20px" : "0",
      }}>
        <Card
          ref={contactRef}
          className={`${isVisibleContact ? "slide-in-from-right visible" : ""}`}
          style={{
            width: "100%",
            height: "auto",
            padding: isSmallScreen ? "20px" : isMediumScreen ? "30px" : "37px",
            backgroundImage: `url(${testimonialsimg})`,
            borderRadius: "15px",
            margin: "0 auto",
            boxSizing: "border-box",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              style={{
                color: "#FFFFFF",
                fontFamily: "Spartan, sans-serif",
                fontSize: isSmallScreen ? "18px" : isMediumScreen ? "20px" : "25px",
                fontWeight: "bold",
                paddingBottom: "14px",
              }}
            >
              Get in Touch
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "#BABABA",
                fontFamily: "Poppins, sans-serif",
                fontSize: isSmallScreen ? "13px" : isMediumScreen ? "13px" : "15px",
                fontWeight: "bold",
                margin: "10px 0 0",
              }}
            >
              Please feel free to contact us through the form below. We will get back to you as soon as possible.
            </Typography>
            <form style={{ padding: "20px 0 10px" }}>
              <TextField
                placeholder="Your Name"
                fullWidth
                color="primary"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: {
                    borderBottom: "1px solid #BABABA",
                    color: "#BABABA",
                    "&:focus": { color: "inherit" },
                    fontSize: isSmallScreen ? "14px" : "inherit",
                  },
                }}
              />
              <TextField
                placeholder="Enter Your Email"
                fullWidth
                color="primary"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: {
                    borderBottom: "1px solid #BABABA",
                    color: "#BABABA",
                    "&:focus": { color: "inherit" },
                    fontSize: isSmallScreen ? "14px" : "inherit",
                  },
                }}
              />
              <TextField
                placeholder="Phone Number"
                fullWidth
                color="primary"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: {
                    borderBottom: "1px solid #BABABA",
                    color: "#BABABA",
                    "&:focus": { color: "inherit" },
                    fontSize: isSmallScreen ? "14px" : "inherit",
                  },
                }}
              />
              <TextField
                placeholder="Your Message"
                fullWidth
                color="primary"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                multiline
                rows={5}
                InputProps={{
                  style: {
                    borderBottom: "2px solid #BABABA",
                    color: "#BABABA",
                    "&:focus": { color: "inherit" },
                    fontSize: isSmallScreen ? "14px" : "inherit",
                  },
                }}
              />
              <Button
                variant="contained"
                style={{
                  background: 'linear-gradient(90deg, #3200C1, #B80EE3)',
                  color: "#ffffff",
                  marginTop: "15px",
                  fontSize: isSmallScreen ? "14px" : "inherit",
                }}
              >
                Submit Message
                <ArrowForwardIcon sx={{ width: isSmallScreen ? 25 : 35, height: isSmallScreen ? 18 : 23 }} />
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default FAQs;