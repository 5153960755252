import React, { useState } from "react";
import NavBar from "./Navigation/NavBar";
import SlidingGallery from "./Gallery/SlidingGallery";
import Welcome from "./Welcome";
import Leads from "./Leads";
import Courses from "./Courses";
import About from "./About";
import WhyUs from "./WhyUs";
import Team from "./Team";
import Testimonials from "./Testimonials";
import FAQs from "./FAQs";
import Footer from "./Footer";
import FooterEnd from "./FooterEnd";
import PopUp from "./PopUp";

const Home = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(true);

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  return (
    <div>
      <NavBar />
      <SlidingGallery />
      <Welcome />
      <Leads />
      <Courses />
      <About />
      <WhyUs />
      <Team />
      <Testimonials />
      <FAQs />
      <Footer />
      <FooterEnd />
      <PopUp open={isPopUpOpen} handleClose={handlePopUpClose} />
    </div>
  );
};

export default Home;