import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Register from "./components/Register";
import EnrollPage from "./components/EnrollPage";
import NavBar from "./components/Navigation/NavBar";
import Purchase from "./components/Purchase";
import Courses from "./components/Courses";
import About from "./components/About";
import FAQs from "./components/FAQs";
import Refund from "./components/Refund";
import TandC from "./components/TandC";
import Disclaimer from "./components/Disclaimer";

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/enroll/:id" element={<EnrollPage />} />
        <Route path="/Purchase/:id" element={<Purchase />} />
        <Route path="/Refund" element={<Refund />} />
        <Route path="/TandC" element={<TandC />} />
        <Route path="/Disclaimer" element={<Disclaimer />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<FAQs />} />
      </Routes>
    </div>
  );
}

export default App;