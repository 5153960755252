import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PopUp = ({ open, handleClose }) => {
  return (
    <Dialog PaperProps={{
      style: {
        borderRadius: "8%",
        overflow: "hidden",
        border: "3px solid white",
      },
    }}
      open={open}
      onClose={handleClose}
      aria-labelledby="popup-dialog-title"
      maxWidth="xs"
      
    >
      <DialogTitle style={{backgroundColor: "#380E67",}}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          {/* Icon */}
          <div
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              padding: "15px",
            }}
          >
            <CheckCircleIcon style={{ fontSize: "48px", color: "#FFFFFF" }} />
          </div>
          {/* Sign In Heading */}
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Spartan, sans-serif",
              color: "#DFDF1E",
              margin: "10px 0 10px",
              paddingTop: "20px",
              fontWeight: "bold",
            }}
          >
            Sign In
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#380E67",  }}>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: "#FFFFFF",
            margin: "0 0 8px",
          }}
        >
          Refer ID or Email Addreess
        </Typography>
        <TextField
          fullWidth
          variant="filled"
          placeholder="Your refer id or email" required
          InputProps={{
            sx: {
              borderRadius: 2,
              backgroundColor: "#380E67",
              borderColor: "transparent",
              color: "#FFFFFF",
              "&::placeholder": {
                color: "#BABABA",
              },
            },
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: "#FFFFFF",
            margin: "0 0 8px",
          }}
        >
         Password
        </Typography>
        <TextField
          fullWidth
          variant="filled"
          placeholder="Enter Password" required
          type="password" 
          InputProps={{
            sx: {
              borderRadius: 2,
              backgroundColor: "#380E67",
              borderColor: "transparent",
              color: "#FFFFFF",
              "&::placeholder": {
                color: "#BABABA",
              },
            },
          }}
        />
        <Button
          variant="contained"
          sx={{ width: "100%", backgroundColor: "#DFDF1E", color: "black", borderRadius: 6 }}
          endIcon={<ArrowForwardIcon />}
          onClick={handleClose}
        >
          Sign In
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PopUp;
