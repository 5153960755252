import React from 'react';
import { Typography } from '@mui/material';
import './App.css';

const FooterEnd = () => {
  return (
    <div style={{ textAlign: 'center', backgroundColor: '#080667', width: "100vw  " }}>
      <Typography
        variant="body1"
        style={{
          fontFamily: "Poppins, sans-serif",
          fontSize: "15px",
          color: "#BABABA",
          padding: "10px 0",
        }}
      >
        Copyright &copy; 2023 <span className="options" style={{ fontWeight: "bold" }}>SkillsCraze</span>. All Rights Reserved.
      </Typography>
    </div>
  );
};

export default FooterEnd;
