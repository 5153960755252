import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Founder from "../assets/teammembers/mohit.jpg";
import anuj from "../assets/teammembers/anuj.jpg";
import anujkumar from "../assets/teammembers/anujkumar.jpg";
import nitin from "../assets/teammembers/nitin.jpg";
import testimonialsimg from "../assets/testimonialsblock.png";
import sample from "../assets/teammembers/sample.png";

const teamMembers = [
  { name: "Anuj Bhadoria", image: anujkumar, position: "CEO" },
  { name: "Jyoti Patidar", image: sample, position: "CMO" },
  { name: "Nitin Kurmi", image: nitin, position: "CIO" },
  { name: "Anuj Upadhyay", image: anuj, position: "F.O." },
];

const buttonStyle = {
  position: "relative",
  backgroundColor: "#181036",
  borderRadius: "20px",
  color: "#FFFFFF",
  fontFamily: "Spartan, sans-serif",
  textTransform: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "20px",
    padding: "2px",
    background: "linear-gradient(60deg, #BA11E5, #1227DE)",
    WebkitMask:
      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    WebkitMaskComposite: "xor",
    maskComposite: "exclude",
  },
};

const Team = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${testimonialsimg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        pt: { xs: 4, md: 8 },
        pb: { xs: 4, md: 8 },
        position: "relative",
      }}
    >
      <Typography
        sx={{
          color: "#FFFFFF",
          fontFamily: "Spartan, sans-serif",
          fontSize: { xs: 36, sm: 48, md: 60 },
          fontWeight: "bold",
          mb: { xs: 3, md: 5 },
          textAlign: "center",
        }}
      >
        Team
      </Typography>
      {!isMobile && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "2px",
            height: { sm: "400px", md: "562px" },
            backgroundColor: "white",
            mt: 4,
            display: { xs: "none", sm: "block" },
          }}
        />
      )}
      <Grid
        container
        spacing={isMobile ? 4 : 2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              src={Founder}
              alt="Founder"
              sx={{
                width: { xs: 200, sm: 250, md: 338.42 },
                height: { xs: 200, sm: 250, md: 332.23 },
                border: "5px solid #677726",
                mb: 1,
              }}
            />
            <Button
              variant="contained"
              sx={{
                ...buttonStyle,
                width: { xs: "80%", sm: "288px" },
                height: { xs: "70px", sm: "91.89px" },
                fontSize: { xs: 16, sm: 20 },
                mt: 1,
                fontWeight: "bold",
              }}
            >
              <span style={{ opacity: 0.8 }}>Mohit Tiwari</span>
              <span
                style={{
                  fontSize: "0.8em",
                  marginTop: "5px",
                  fontWeight: "bold",
                  opacity: 1,
                }}
              >
                Founder
              </span>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {teamMembers.map((member, index) => (
              <Grid item xs={6} key={index} sx={{ textAlign: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    opacity: 0.8,
                  }}
                >
                  <Avatar
                    src={member.image}
                    alt={member.name}
                    sx={{
                      width: { xs: 100, sm: 120, md: 154 },
                      height: { xs: 100, sm: 120, md: 154 },
                      mb: 1.3,
                      border: "4px solid #235197",
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      ...buttonStyle,
                      width: { xs: "90%", sm: 140 },
                      height: { xs: 55, sm: 65 },
                      fontSize: { xs: 12, sm: 14 },
                      mt: 1,
                      mb: { xs: 2, md: 4 },
                    }}
                  >
                    <span style={{ opacity: 0.8 }}>{member.name}</span>
                    <span
                      style={{
                        fontSize: "0.8em",
                        marginTop: "5px",
                        fontWeight: "bold",
                        opacity: 1,
                      }}
                    >
                      {member.position}
                    </span>
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Team;
