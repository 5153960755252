import React from 'react';
import Footer from './Footer.jsx'
import FooterEnd from './FooterEnd.jsx'
import "./App.css";
import testimonialsblock from '../assets/testimonialsblock.png';
import bgphoto from '../assets/bgphoto.png';

const TandC = () => {
  return (
    <div>
    <div className="tandc-policy" style={{
      backgroundImage: `url(${testimonialsblock})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      padding: '40px',
      fontFamily: 'Arial, sans-serif'
    }}>
      <div className="content" style={{
        backgroundImage: `url(${bgphoto})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '30px',
        borderRadius: '15px',
        maxWidth: '800px',
        margin: '0 auto 40px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <div style={{
          backgroundImage: `url(${bgphoto})`,
          padding: '30px',
          borderRadius: '15px',
        }}>
          <h1 style={{
            textAlign: 'center', 
            marginBottom: '30px',
            color: 'white',
            padding: '20px',
            borderBottom: '2px solid #3498db'
          }}>Terms and Conditions</h1>
          
          <div style={{
            backgroundColor: '#dfdfde',
            borderRadius: '10px',
            padding: '20px',
            marginBottom: '20px'
          }}>
            <h2 style={{
              color: '#e74c3c',
              paddingBottom: '10px',
              borderBottom: '1px solid #e74c3c'
            }}>1. Acceptance of Terms</h2>
            <p>By accessing and using SkillsCraze, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our website or services.</p>
          </div>
          
          {[
            { title: '2. Course Access and Usage', content: 'Upon purchase, you will be granted access to the course materials for the duration specified. You may not share your account or course materials with others.' },
            { title: '3. Payment and Refunds', content: 'All payments are to be made in full before accessing course content. We have a strict no-refund policy unless otherwise stated for specific courses.' },
            { title: '4. Intellectual Property', content: 'All course materials, including videos, documents, and assignments, are the property of SkillsCraze and are protected by copyright laws. You may not reproduce or distribute these materials without explicit permission.' },
            { title: '5. User Conduct', content: 'You agree to use our platform responsibly and not engage in any behavior that may disrupt the learning experience of others or compromise the integrity of our systems.' },
            { title: '6. Limitation of Liability', content: 'SkillsCraze is not liable for any direct, indirect, incidental, or consequential damages resulting from your use of our services or inability to access our content.' },
            { title: '7. Modifications to Terms', content: 'We reserve the right to modify these terms at any time. Continued use of our services after changes constitutes acceptance of the new terms.' },
            { title: '8. Governing Law', content: 'These terms are governed by the laws of India, and any disputes shall be subject to the exclusive jurisdiction of the courts in India.' },
            { title: '9. Contact Us', content: 'If you have any questions about these Terms and Conditions, please contact us at support@SkillsCraze.com.' }
          ].map((section, index) => (
            <div key={index} style={{
              backgroundColor: '#dfdfde',
              padding: '15px',
              marginTop: '20px',
              borderRadius: '10px'
            }}>
              <h3 style={{
                color: '#16a085',
                paddingBottom: '10px',
                borderBottom: '1px solid #16a085'
              }}>{section.title}</h3>
              <p>{section.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
      <Footer />
      <FooterEnd />
    </div>
  );
}

export default TandC;