import React, { useState, useEffect, useMemo } from "react";
// import galleryimg1 from "../../assets/galleryimg1.jpg";
import galleryimg2 from "../../assets/galleryimg2.jpg";
import galleryimg3 from "../../assets/galleryimg3.jpg";
import galleryimg4 from "../../assets/galleryimg4.jpg";
import galleryimg1 from "../../assets/galleryimg5.jpg";

import { Box, IconButton, useMediaQuery, useTheme, Grid} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const SlidingGallery = () => {
  const images = useMemo(() => [galleryimg1, galleryimg2, galleryimg3, galleryimg4], []);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
  
    return () => {
      clearInterval(intervalId);
    };
  }, [images]);

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleImageClick = (index) => {
    console.log("Image opened:", index);
  };

  return (
    <div style={{ maxWidth: "100vw", overflow: "hidden" }}>
      <Grid
        container
        alignItems="center"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <IconButton
          style={{ position: "absolute", left: "20px", color: "#FFFFFF", zIndex: 1 }}
          onClick={handlePrev}
        >
          <ArrowBackIos />
        </IconButton>
        <img
          src={images[currentImageIndex]}
          style={{ width: "100vw", height: isSmallScreen ? "300px" : "700px",
            height: isMediumScreen ? "350px" : "700px",zIndex: 0 }}
          alt={`gallery-img-${currentImageIndex + 1}`}
        />
        <IconButton
          style={{ position: "absolute", right: "20px", color: "#FFFFFF", zIndex: 1 }}
          onClick={handleNext}
        >
          <ArrowForwardIos />
        </IconButton>
        <Grid
          container
          justifyContent="center"
          style={{ position: "absolute", bottom: "10px", width: "100%", zIndex: 1 }}
        >
          {images.map((_, index) => (
            <Grid
              key={index}
              item
              style={{ margin: "0 5px", cursor: "pointer" }}
              onClick={() => handleImageClick(index)}
            >
              {currentImageIndex === index ? (
                <div
                  style={{
                    backgroundColor: "white",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: "black",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default SlidingGallery;