import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const PopUp = ({ open, handleClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleInputChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  // const handleSubmit = () => {
  //   if (phoneNumber.trim() !== "") {
  //     Submit logic goes here
  //     console.log("Submitting with phone number:", phoneNumber);
  //     handleClose();
  //   }
  // };

  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      aria-labelledby="popup-dialog-title"
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: "8%",
          overflow: "hidden",
          border: "3px solid white",
          
        },
      }}
    >
      <DialogTitle style={{ backgroundColor: "#380E67" }}>
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Spartan, sans-serif",
            color: "#DFDF1E",
            margin: "0 0 5px",
            fontWeight: "bold",
          }}
        >
          Request a Call Back!
        </Typography>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#380E67", padding: "15px" }}>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: "#FFFFFF",
            margin: "0 0 8px",
          }}
        >
          Enter your contact number to get a call from our team.
        </Typography>
        <TextField
          fullWidth
          variant="filled"
          placeholder="Enter your contact number"
          required
          type="number"
          value={phoneNumber}
          onChange={handleInputChange}
          InputProps={{
            sx: {
              borderRadius: 6,
              backgroundColor: "#270052",
              borderColor: "transparent",
              color: "#FFFFFF",
              "&::placeholder": {
                color: "#BABABA",
              },
            },
          }}
        />
        <List>
          {[
            "Advance career guidance",
            "Live qualitative training",
            "Advance career guidance",
          ].map((text, index) => (
            <ListItem key={index} disableGutters sx={{ margin: "0 0 8px" }}>
              <ListItemIcon>
                <CheckCircleIcon sx={{ color: "#00FF00" }} />
              </ListItemIcon>
              <ListItemText
                primary={text}
                sx={{ color: "#FFFFFF", fontFamily: "Poppins, sans-serif" }}
              />
            </ListItem>
          ))}
        </List>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: "#FFFFFF",
            fontStyle: "italic",
            margin: "0 0 8px",
          }}
        >
          We will give you a call between 9:30 AM to 6:00 PM
        </Typography>
        <Button
          variant="contained"
          sx={{ width: "100%", backgroundColor: "#DFDF1E", color: "black", borderRadius: 6 }}
          endIcon={<ArrowForwardIcon />}
          onClick={handleClose}
        >
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PopUp;
