import React from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import PopUp from "./LogInPopUp.jsx";
import logo from "../assets/logo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './App.css';

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <div className="footer-container"
      style={{
        width: "100vw",
        backgroundColor: "#080667",
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <div style={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        justifyContent: "space-between",
        flexWrap: isMediumScreen ? "wrap" : "nowrap",
      }}>
        {/* Left Section */}
        <div style={{ width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "25%", padding: "10px 20px" }}>
          <img
            src={logo}
            alt="Icon"
            style={{ width: "170px", paddingTop: "10px", paddingRight: "20px" }}
          />
          <div style={{
            width: "100%",
            maxWidth: "320px",
            fontFamily: "Poppins, sans-serif",
            fontSize: "17px",
            color: "#BABABA",
            margin: "0 0 10px",
            paddingTop: "20px",
          }}>
            Our platform empowers learners to enhance their skills and knowledge
            through accessible and comprehensive learning resources.
          </div>
          <div style={{
            display: "flex",
            gap: "15px",
            fontSize: "20px",
            color: "#dfdf1e",
            cursor: 'pointer'
          }}>
            <a className="optionsfooter"
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{color: "#FFFFFF"}}
            >
              <FacebookIcon />
            </a>
            <a className="optionsfooter"
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{color: "#FFFFFF"}}
            >
              <InstagramIcon />
            </a>
            <a className="optionsfooter"
              href="https://www.youtube.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{color: "#FFFFFF"}}
            >
              <YouTubeIcon />
            </a>
          </div>
        </div>

        {/* Middle Sections */}
        <div style={{ width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "25%", padding: "10px", paddingLeft: "25px" }}>
          <div style={{
            fontFamily: "Spartan , sans-serif",
            fontSize: "20px",
            color: "#FFFFFF",
            fontWeight: "bold",
            margin: "20px 0 20px",
          }}>
            Online Platform
          </div>
          <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
            <li className="options"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                margin: "20px 0",
                listStyle: "square",
              }}
            >
              <a href="#about" className="nav-link">About</a>
            </li>
            <li className="options"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                margin: "20px 0",
                listStyle: "square",
              }}
            >
              <a href="#contact" className="nav-link">Contact Us</a>
            </li>
            <li className="options"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                margin: "20px 0",
                listStyle: "square",
              }}
            >
              <a href="#PopUp" className="nav-link" onClick={PopUp}>
                Login
              </a>
            </li>
            <li className="options"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                margin: "20px 0",
                listStyle: "square",
              }}
            >
              <Link to="/register" className="nav-link">
                Register
              </Link>
            </li>
          </ul>
        </div>

        <div style={{ width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "25%", padding: "10px" }}>
          <div style={{
            fontFamily: "Spartan , sans-serif",
            fontSize: "20px",
            color: "#FFFFFF",
            fontWeight: "bold",
            margin: "30px 0 20px",
          }}>
            Important Links
          </div>
          <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
            <li className="options"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                margin: "20px 0",
                listStyle: "square",
              }}
            >
              <Link to="/disclaimer" className="nav-link">
                Disclaimer
              </Link>
            </li>
            <li className="options"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                margin: "20px 0",
                listStyle: "square",
              }}
            >
              <Link to="/refund" className="nav-link">
                Refund & Privacy Policy
              </Link>
            </li>
            <li className="options"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                margin: "20px 0",
                listStyle: "square",
              }}
            >
              <Link to="/tandc" className="nav-link">
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>

        {/* Right Section */}
        <div style={{ width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "25%", padding: "10px" }}>
          <div style={{
            fontFamily: "Spartan , sans-serif",
            fontSize: "20px",
            color: "#FFFFFF",
            fontWeight: "bold",
            margin: "30px 0 20px",
          }}>
            Contact Us
          </div>
          <div style={{ display: "flex", alignItems: "center", margin: "0 0 10px" }}>
            <PhoneIcon className="optionsfooter"
              style={{
                display: "flex",
                gap: "15px",
                fontSize: "20px",
                color:'#FFFFFF'
              }}
            />
            <Typography className="options"
              variant="body1"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                margin: "20px 0",
                paddingLeft: "10px",
              }}
            >
              +91 8108072914
              Gauri Muranjan
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <WhatsAppIcon className="optionsfooter"
              style={{
                display: "flex",
                gap: "15px",
                fontSize: "20px",
                color:'#FFFFFF'
              }}
            />
            <Typography className="options"
              variant="body1"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                margin: "10px 0",
                paddingLeft: "10px",
              }}
            >
              <a className="whatsapp" href="http://wa.me/+918108072914">Skillscraze Support Team</a>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;