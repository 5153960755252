import googleads from "../assets/googleads.png";
import growthmasterynetworkmarketing from "../assets/growthmasterynetworkmarketing.png";
import howtoconvincepeople from "../assets/howtoconvincepeople.png";
import leadgencourse from "../assets/leadgencourse.png";
import salesmastery from "../assets/salesmastery.png";
import stockmarket from "../assets/stockmarket.png";
import { useMediaQuery } from "@mui/material";

import { useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  LibraryBooks as LibraryBooksIcon,
  WhatsApp as WhatsAppIcon,
  Star as StarIcon,
} from "@mui/icons-material";
import "./App.css";
import bgphoto from "../assets/bgphoto.png";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  useTheme
} from "@mui/material";

const Courses = () => {
  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isVisible, setIsVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const whyUsRef = useRef(null);

  const courses = [
    {
      id: 1,
      name: "Lead Gen. Course",
      image: leadgencourse,
      rating: 4,
      description:
        "Stock Market | Crypto Currency | Website Development | +12 More Courses",
      videos: 8,
      whatsappSupport: true,
      price: 4999,
    },
    {
      id: 2,
      name: "How to Convince People",
      image: howtoconvincepeople,
      rating: 4,
      description:
        "Stock Market | Crypto Currency | Website Development | +12 More Courses",
      videos: 8,
      whatsappSupport: true,
      price: 2500,
    },
    {
      id: 3,
      name: "Sales Mastery",
      image: salesmastery,
      rating: 4,
      description:
        "Stock Market | Crypto Currency | Website Development | +12 More Courses",
      videos: 8,
      whatsappSupport: true,
      price: 2999,
    },
    {
      id: 4,
      name: "Google Ads",
      image: googleads,
      rating: 5,
      description:
        "Stock Market | Crypto Currency | Website Development | +12 More Courses",
      videos: 10,
      whatsappSupport: true,
      price: 1600,
    },
    {
      id: 5,
      name: "Stock Market",
      image: stockmarket,
      rating: 4,
      description:
        "Stock Market | Crypto Currency | Website Development | +12 More Courses",
      videos: 8,
      whatsappSupport: true,
      price: 8000,
    },
    {
      id: 6,
      name: "Network Marketing Growth Mastery",
      image: growthmasterynetworkmarketing,
      rating: 4,
      description:
        "Stock Market | Crypto Currency | Website Development | +12 More Courses",
      videos: 8,
      whatsappSupport: true,
      price: 12000,
    },
  ];

  const handleScroll = useCallback(() => {
    setScrollPosition(window.pageYOffset);
    if (whyUsRef.current) {
      const topPosition = whyUsRef.current.getBoundingClientRect().top;
      const isVisible = topPosition < window.innerHeight - 100;
      setIsVisible(isVisible);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div id="courses">
      <div
        style={{ 
          backgroundImage: `url(${bgphoto})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          padding: isSmallScreen ? "50px 20px 50px" : "95px 20px 92px",
          boxSizing: "border-box",
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography
              ref={whyUsRef}
              className={`${
                isVisible && scrollPosition > 0
                  ? "popular slide-up-animation"
                  : "popular"
              } ${isVisible ? "visible" : ""}`}
              variant="body1"
              style={{
                color: "#bab8b7",
                fontSize: isSmallScreen ? "15px" : "15px",
                fontFamily: "'Poppins', sans-serif",
                margin: "0 auto 14px",
                fontWeight: "bold",
              }}
            >
              POPULAR COURSES
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={`${
                isVisible && scrollPosition > 0
                  ? "pick slide-up-animation"
                  : "pick"
              } ${isVisible ? "visible" : ""}`}
              variant="h3"
              style={{
                color: "#ffffff",
                fontSize: isSmallScreen ? "30px" : "40px",
                fontFamily: "'Spartan', sans-serif",
                margin: "0px auto 4px",
                fontWeight: "bold",
                textAlign: "center",
                padding: isSmallScreen ? "0 0 50px 0" : "0 0 90px 0",
              }}
            >
              Pick a new skill box to develop itself
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {courses.map((course) => (
            <Grid
              className={`${
                isVisible && scrollPosition > 0
                  ? "zoom course-card slide-up-animation"
                  : "zoom course-card"
              } ${isVisible ? "visible" : ""}`}
              item
              xs={12}
              sm={12}
              md={isMediumScreen ? 12 : 6}
              key={course.id}
            >
              <Card
                style={{
                  backgroundColor: "#0C0057",
                  height: "auto",
                  width: "100%",
                  maxWidth: isMediumScreen ? "100%" : "590px",
                  margin: "0 auto",
                }}
              >
                <CardContent>
                  <Grid container direction={isSmallScreen ? "column" : "row"} alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <div className="cardMedia"
                        style={{
                          width: "100%",
                          maxWidth: isSmallScreen ? "200px" : "200px",
                          height: isSmallScreen ? "auto" : "270px",
                          backgroundColor: "#010036",
                          padding: isSmallScreen ? "20px 0" : "33px 0",
                          margin: isSmallScreen ? "0 auto 20px" : "0",
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="100%"
                          image={course.image}
                          alt={course.name}
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        className="options"
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{
                          fontFamily: "Spartan, sans-serif",
                          fontSize: isSmallScreen ? "20px" : "18px",
                          fontWeight: "bold",
                          color: "#ffffff",
                          marginBottom: "10px",
                        }}
                      >
                        {course.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="#ffffff"
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: isSmallScreen ? "17px" : "15px",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {[...Array(course.rating)].map((_, index) => (
                          <StarIcon
                            key={index}
                            sx={{
                              color: "#f8b81f",
                              fontSize: isSmallScreen ? "17px" : "19px",
                              marginRight: "3px",
                            }}
                          />
                        ))}
                        <div style={{ marginLeft: "5px" }}>
                          ({course.rating}.0 / 5 Rating)
                        </div>
                      </Typography>

                      <div style={{ padding: "20px" }}>
                        <Typography
                          variant="body2"
                          color="#ffffff"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: isSmallScreen ? "14px" : "15px",
                          }}
                        >
                          {course.description}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="#ffffff"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: isSmallScreen ? "14px" : "15px",
                            marginTop: "5px",
                          }}
                        >
                          <LibraryBooksIcon
                            sx={{
                              color: "#ffffff",
                              fontSize: isSmallScreen ? "18px" : "20px",
                              marginRight: "3px",
                              verticalAlign: "middle",
                            }}
                          />
                          {course.videos} Exclusive Video Tutorials
                        </Typography>
                        <Typography
                          variant="body2"
                          color="#ffffff"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: isSmallScreen ? "14px" : "15px",
                            marginTop: "5px",
                          }}
                        >
                          <WhatsAppIcon
                            sx={{
                              color: "#ffffff",
                              fontSize: isSmallScreen ? "18px" : "20px",
                              marginRight: "3px",
                              verticalAlign: "middle",
                            }}
                          />
                          WhatsApp Support
                        </Typography>
                        <Typography
                          variant="body2"
                          color="#ffffff"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: isSmallScreen ? "14px" : "15px",
                            marginTop: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          ₹{course.price} /-
                        </Typography>
                      </div>
                      <Link
                        to={`/enroll/${course.id}?name=${encodeURIComponent(
                          course.name
                        )}&image=${encodeURIComponent(course.image)}&rating=${
                          course.rating
                        }&description=${encodeURIComponent(
                          course.description
                        )}&videos=${course.videos}&whatsappSupport=${
                          course.whatsappSupport
                        }&price=${course.price}`}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            background:
                              "linear-gradient( 90deg, #3200C1,#B80EE3 )",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "bold",
                            marginTop: "10px",
                            border: "3px solid white",
                          }}
                        >
                          Enroll Now
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Courses;