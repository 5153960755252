import { Grid, Box, useTheme, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const NavLinks = ({ isHomePage }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid 
      container 
      item 
      xs={12}
      spacing={isMobileOrTablet ? 3 : 14}
      direction={isMobileOrTablet ? "column" : "row"}
    >
      <Grid item>
        {isHomePage ? (
          <ScrollLink
            to="home"
            className="nav-link"
            spy={true}
            smooth={true}
            offset={-70} 
            duration={500}
          >
            <Box py={1}>Home</Box>
          </ScrollLink>
        ) : (
          <RouterLink
            to="/"
            className="nav-link"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <Box py={1}>Home</Box>
          </RouterLink>
        )}
      </Grid>
      <Grid item>
        <ScrollLink
          to="courses"
          className="nav-link"
          spy={true}
          smooth={true}
          offset={-70} 
          duration={500}
        >
          <Box py={1}>Courses</Box>
        </ScrollLink>
      </Grid>
      <Grid item>
        <ScrollLink
          to="about"
          className="nav-link"
          spy={true}
          smooth={true}
          offset={-70} 
          duration={500}
        >
          <Box py={1}>About Us</Box>
        </ScrollLink>
      </Grid>
      <Grid item>
        <ScrollLink
          to="contact"
          className="nav-link"
          spy={true}
          smooth={true}
          offset={-70} 
          duration={500}
        >
          <Box py={1}>Contact Us</Box>
        </ScrollLink>
      </Grid>
    </Grid>
  );
};

export default NavLinks;