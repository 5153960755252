import Footer from './Footer.jsx'
import FooterEnd from './FooterEnd.jsx'
import testimonialsblock from '../assets/testimonialsblock.png';
import bgphoto from '../assets/bgphoto.png';

const Refund = () => {
  const contentSections = [
    'Information We Collect',
    'How We Use Your Information',
    'Information Sharing and Disclosure',
    'Data Security',
    'Your Rights',
    'Changes to This Policy',
    'Contact Us'
  ];

  return (
    <div>
      <div className="refund-policy" style={{
        backgroundImage: `url(${testimonialsblock})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        padding: '40px',
        fontFamily: 'Arial, sans-serif'
      }}>
        <div className="content" style={{
          backgroundImage: `url(${bgphoto})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '30px',
          borderRadius: '15px',
          maxWidth: '800px',
          margin: '0 auto 40px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}>
          <h1 style={{
            textAlign: 'center', 
            marginBottom: '30px',
            color: 'white',
            padding: '20px',
            borderBottom: '2px solid #3498db'
          }}>Refund and Privacy Policy</h1>
          
          <div style={{
            backgroundColor: '#dfdfde',
            borderRadius: '10px',
            padding: '20px',
            marginBottom: '20px'
          }}>
            <h2 style={{
              color: '#e74c3c',
              paddingBottom: '10px',
              borderBottom: '1px solid #e74c3c'
            }}>Refund Policy</h2>
            <p>At SkillsCraze, we have a strict no-refund policy. All sales are final and we do not offer refunds for any products or services purchased on our site. Please carefully consider your purchase before completing the transaction.</p>
          </div>
          
          <h2 style={{
            color: '#ffffff',
            padding: '15px 0',
            borderBottom: '1px solid #2980b9'
          }}>Privacy Policy</h2>
          <p>At SkillsCraze, we value your privacy and are committed to protecting your personal information. This privacy policy outlines how we collect, use, and safeguard your data.</p>
          
          {contentSections.map((heading, index) => (
            <div key={heading} style={{
              backgroundColor: '#dfdfde',
              padding: '15px',
              marginTop: '20px',
              borderRadius: '10px'
            }}>
              <h3 style={{
                color: '#16a085',
                paddingBottom: '10px',
                borderBottom: '1px solid #16a085'
              }}>{heading}</h3>
              <p>{
                heading === 'Contact Us' 
                  ? 'If you have any questions or concerns about our refund or privacy policy, please contact us at support@SkillsCraze.com.'
                  : `Content for ${heading} goes here.`
              }</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <FooterEnd />
    </div>
  );
}

export default Refund;