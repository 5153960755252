import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import bgphoto from "../assets/bgphoto.png"
import './App.css'

const Welcome = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const responsiveStyles = {
    container: {
      textAlign: "center",
      width: "100%",
      height: isMediumScreen ? "auto" : "187px",
      backgroundImage: `url(${bgphoto})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      paddingTop: isMediumScreen ? "40px" : "60px",
      paddingBottom: isMediumScreen ? "40px" : "0",
      paddingLeft: "0",  
      paddingRight: "0", 
      overflowX: "hidden",
    },
    welcome: {
      fontSize: isSmallScreen ? "15px" : "15px",
      marginBottom: isMediumScreen ? "10px" : "0",
    },
    heading: {
      fontSize: isSmallScreen ? "26px" : isMediumScreen ? "30px" : "35px",
      paddingTop: isMediumScreen ? "15px" : "25px",
    },
    subheading: {
      fontSize: isSmallScreen ? "12px" : isMediumScreen ? "15px" : "17px",
      paddingTop: isMediumScreen ? "15px" : "25px",
      maxWidth: isMediumScreen ? "100%" : "none", 
      margin: "0 auto",
    },
  };

  return (
    <div className="welcome overflow-hidden" style={responsiveStyles.container}>
      <Grid container direction="column" alignItems="center" style={{ height: "100%" }}>
        <Grid item>
          <Typography
            variant="h5"
            className="one hidden"
            style={{
              color: "#bababa",
              fontFamily: "Poppins, sans-serif",
              margin: "0 auto",
              ...responsiveStyles.welcome,
            }}
          >
            WELCOME TO SKILLSCRAZE
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            className="two"
            style={{
              color: "#ffffff",
              fontFamily: "Spartan, sans-serif",
              fontWeight: "bold",
              margin: "0 auto",
              ...responsiveStyles.heading,
            }}
          >
            Creating A Community Of Learners
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            className="three"
            style={{
              color: "#bababa",
              fontFamily: "Poppins, sans-serif",
              margin: "10px auto 24px",
              ...responsiveStyles.subheading,
            }}
          >
            Success is a journey, Not a destination. The doing is often more important than the outcome
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Welcome;