
import Footer from './Footer.jsx'
import FooterEnd from './FooterEnd.jsx'
import "./App.css";
import testimonialsblock from '../assets/testimonialsblock.png';
import bgphoto from '../assets/bgphoto.png';

const Disclaimer = () => {
  return (
    <div>
     <div className="disclaimer-policy" style={{
        backgroundImage: `url(${testimonialsblock})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        padding: '40px',
        fontFamily: 'Arial, sans-serif'
      }}>
      <div className="content" style={{
        backgroundImage: `url(${bgphoto})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '30px',
        borderRadius: '15px',
        maxWidth: '800px',
        margin: '0 auto 40px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <div style={{
          backgroundImage: `url(${bgphoto})`,
          padding: '30px',
          borderRadius: '15px',
        }}>
          <h1 style={{
            textAlign: 'center', 
            marginBottom: '30px',
            color: 'white',
            padding: '20px',
            borderBottom: '2px solid #3498db'
          }}>Disclaimer</h1>
          
          <div style={{
            backgroundColor: '#dfdfde',
            borderRadius: '10px',
            padding: '20px',
            marginBottom: '20px'
          }}>
            <h2 style={{
              color: '#e74c3c',
              paddingBottom: '10px',
              borderBottom: '1px solid #e74c3c'
            }}>General Disclaimer</h2>
            <p>The information provided on SkillsCraze is for general informational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.</p>
          </div>
          
          {[
            { title: 'Educational Content', content: 'The courses and materials on SkillsCraze are intended for educational purposes only. They do not constitute professional advice and should not be relied upon as such. Users should consult with appropriate professionals for specific advice tailored to their situation.' },
            { title: 'User Responsibility', content: 'Any reliance you place on the information provided on this website is strictly at your own risk. It is your responsibility to ensure that any products, services or information available through this website meet your specific requirements.' },
            { title: 'Results May Vary', content: 'The results described in testimonials or case studies on our website are not guaranteed and may not be typical for all users. Individual results may vary depending on various factors including but not limited to personal effort, background, and circumstances.' },
            { title: 'Third-Party Links', content: 'SkillsCraze may contain links to external websites that are not provided or maintained by us. We do not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.' },
            { title: 'Changes to Content', content: 'We reserve the right to modify, update or remove the contents of our courses and website at any time without notice. We are not liable for any inconvenience or loss resulting from such changes.' },
            { title: 'No Guarantee of Certification', content: 'While our courses are designed to help prepare for various certifications, we do not guarantee that completion of our courses will result in passing any certification exams.' },
            { title: 'Limitation of Liability', content: 'In no event will SkillsCraze be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website or our courses.' },
            { title: 'Contact Us', content: 'If you have any questions about this Disclaimer, please contact us at support@SkillsCraze.com.' }
          ].map((section, index) => (
            <div key={index} style={{
              backgroundColor: '#dfdfde',
              padding: '15px',
              marginTop: '20px',
              borderRadius: '10px'
            }}>
              <h3 style={{
                color: '#16a085',
                paddingBottom: '10px',
                borderBottom: '1px solid #16a085'
              }}>{section.title}</h3>
              <p>{section.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
      <Footer />
      <FooterEnd />
    </div>
  );
}

export default Disclaimer;