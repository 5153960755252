import { useEffect, useState, useRef } from "react";
import { Typography, Grid, ListItem, ListItemIcon, useTheme, useMediaQuery } from "@mui/material";
import about from "../assets/about.png";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import "./App.css";
import testimonialsimg from "../assets/testimonialsblock.png";

const About = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const whyUsRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
      
      if (whyUsRef.current) {
        const topPosition = whyUsRef.current.getBoundingClientRect().top;
        const isVisible = topPosition < window.innerHeight - 100;
        setIsVisible(isVisible);
      }
      
      const aboutSection = document.getElementById("about");
      if (aboutSection) {
        const topPosition = aboutSection.getBoundingClientRect().top;
        const bottomPosition = aboutSection.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;

        if (topPosition < windowHeight && bottomPosition > 0) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      id="about"
      style={{
        display: "flex",
        flexDirection: isMediumScreen ? "column" : "row",
        alignItems: "center",
        backgroundImage: `url(${testimonialsimg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        minHeight: isMediumScreen ? "auto" : "680px",
        overflowX: "hidden",
        boxSizing: "border-box",
        color: "#BABABA",
        padding: isMediumScreen ? "40px 20px" : "0",
      }}
    >
      <img
        src={about}
        alt="SkillsCraze"
        style={{
          width: isMediumScreen ? "100%" : "45%",
          maxHeight: isMediumScreen ? "400px" : "90%",
          objectFit: "cover",
          marginBottom: isMediumScreen ? "20px" : "0",
        }}
      />
      <div style={{ width: isMediumScreen ? "100%" : "50%", padding: isMediumScreen ? "0 20px" : "0 40px" }}>
        <Typography 
          ref={whyUsRef}
          className={`${isVisible && scrollPosition > 0 ? "slide-in-from-right" : ""} ${isVisible ? "visible" : ""}`}
          variant="h4"
          gutterBottom
          style={{
            textAlign: "center",
            fontFamily: "Spartan, sans-serif",
            fontSize: isSmallScreen ? "25px" : isMediumScreen ? "30px" : "35px",
            fontWeight: "bold",
            color: "#FFFFFF",
            marginBottom: "20px",
          }}
        >
          Discover the SkillsCraze Difference <span style={{ color: "#2FA495" }}>Empowering</span>{" "}
          Your Career Growth
        </Typography>
        <Typography
          ref={whyUsRef}
          className={`${isVisible && scrollPosition > 0 ? "slide-in-from-right" : ""} ${isVisible ? "visible" : ""}`}
          variant="body1"
          paragraph
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: isSmallScreen ? "14px" : isMediumScreen ? "16px" : "18px",
            marginBottom: "20px",
          }}
        >
          At SkillsCraze, we are passionate about empowering individuals with
          the skills and knowledge they need to achieve their career goals. Our
          platform offers a wide range of courses and training programs,
          delivered by industry experts, to help you stay ahead in today's
          fast-paced and competitive job market. We believe that learning should
          be accessible to everyone, which is why we strive to make our courses
          affordable and flexible, allowing you to learn at your own pace and on
          your own schedule. Join us today and take the first step towards
          achieving your professional aspirations.
        </Typography>
        <Grid
          container
          component="ul"
          spacing={1}
          style={{ listStyleType: "none", padding: 0 }}
        >
          {["Expert Trainers", "Online Remote Learning", "Lifetime Access"].map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              component={ListItem}
              style={{ display: "flex", alignItems: "center" }}
            >
              <ListItemIcon>
                <AssignmentTurnedInIcon
                  ref={whyUsRef}
                  className={`${isVisible && scrollPosition > 0 ? "slide-in-from-right" : ""} ${isVisible ? "visible" : ""}`}
                  style={{
                    color: "#DFDF1E",
                    fontSize: "20px",
                    marginRight: "4px",
                  }}
                />
              </ListItemIcon>
              <Typography
                ref={whyUsRef}
                className={`${isVisible && scrollPosition > 0 ? "slide-in-from-right" : ""} ${isVisible ? "visible" : ""}`}
                variant="body1"
              >
                {item}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default About;