import React from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Footer from "./Footer";
import FooterEnd from "./FooterEnd";
import "./App.css";
import StarIcon from "@mui/icons-material/Star";
import PeopleIcon from "@mui/icons-material/People";
import HeadsetIcon from "@mui/icons-material/Headset";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import bgphoto from "../assets/bgphoto.png";

const EnrollPage = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const courseDetails = {
    id,
    name: queryParams.get("name"),
    image: queryParams.get("image"),
    rating: queryParams.get("rating"),
    price: queryParams.get("price"),
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${bgphoto})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflowX: "hidden",
        boxSizing: "border-box",
        color: "#FFFFFF",
        padding: isSmallScreen ? "50px 20px 0" : "100px 50px 0 0",
        margin: "0 auto",
      }}
    >
      <h2
        style={{
          maxWidth: "960px",
          height: "93.5px",
          margin: "30px auto",
          fontSize: isSmallScreen ? "28px" : "35px",
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: "93.5px",
          color: "#FF00F3",
        }}
      >
        Enroll Course
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: isMediumScreen ? "column" : "row",
          maxWidth: "960px",
          margin: "0 auto",
        }}
      >
        <div style={{ width: isMediumScreen ? "100%" : "480px", marginBottom: isMediumScreen ? "20px" : 0 }}>
          <h2
            style={{
              width: "100%",
              height: "auto",
              fontSize: isSmallScreen ? "22px" : "25px",
              fontFamily: "Poppins, sans-serif",
              color: "#FFFFFF",
              margin: "0 0 12px",
            }}
          >
            {courseDetails.name}
          </h2>
          <ul
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              listStyle: "none",
              padding: "20px 0 20px 0",
              margin: 0,
            }}
          >
            <li
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PeopleIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginRight: "5px",
                  color: "#FF00F3",
                }}
              />
              <span>By SkillsCraze </span>
            </li>
            <li
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <HeadsetIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginRight: "5px",
                  marginLeft: isSmallScreen ? 0 : "30px",
                  color: "#FF00F3",
                }}
              />
              <span>English and Hindi </span>
            </li>
          </ul>
        </div>
        <div
          style={{
            width: isMediumScreen ? "100%" : "456px",
            display: "flex",
            flexDirection: "column",
            alignItems: isMediumScreen ? "flex-start" : "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            {[...Array(5)].map((_, index) => (
              <StarIcon
                key={index}
                sx={{
                  width: 20,
                  height: 20,
                  marginRight: index !== 4 ? 2 : 0,
                  color: "#e2a02b",
                }}
              />
            ))}
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins, sans-serif",
                color: "#727695",
                margin: "0 0 0 2px",
              }}
            >
              ({courseDetails.rating})
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "148px" }}>
              <h3
                style={{
                  fontSize: isSmallScreen ? "30px" : "35px",
                  fontFamily: "Poppins, sans-serif",
                  margin: "0",
                  color: "#FFFFFF",
                }}
              >
                ₹ {courseDetails.price} /-
              </h3>
            </div>
            <Link
              className="enrollNowButton"
              to={`/purchase/${courseDetails.id}?name=${encodeURIComponent(
                courseDetails.name
              )}&price=${courseDetails.price}}`}
              style={{
                width: "161.4px",
                height: "50.7px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                borderRadius: "5px",
                fontFamily: "Poppins, sans-serif",
                fontSize: "17px",
                fontWeight: "bold",
                background: 'linear-gradient( 90deg, #3200C1,#B80EE3 )',
                color: "#ffffff",
                textDecoration: "none",
              }}
            >
              Enroll Now
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMediumScreen ? "column" : "row",
          marginTop: "30px",
          maxWidth: "960px",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            width: isMediumScreen ? "100%" : "640px",
            marginRight: isMediumScreen ? 0 : "20px",
            backgroundColor: "#0C0057",
            padding: "30px 0",
            marginBottom: isMediumScreen ? "20px" : 0,
          }}
        >
          <div
            style={{
              width: isSmallScreen ? "90%" : "400px",
              height: isSmallScreen ? "300px" : "400px",
              overflow: "hidden",
              marginBottom: "15px",
              backgroundColor: "#0C0057",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <img
              src={courseDetails.image}
              alt="Course"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
          <h3
            style={{
              fontSize: isSmallScreen ? "22px" : "25px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              marginBottom: "15px",
              color: "#FFFFFF",
              padding: "10px 0 30px 20px",
            }}
          >
            About The Course
          </h3>
          <p
            style={{
              fontSize: "15px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#FFFFFF",
              padding: "10px 0 30px 20px",
            }}
          >
            Stock Market | Crypto Currency | Website Development | +12 More Courses
          </p>
        </div>
        <div
          style={{
            width: isMediumScreen ? "100%" : "320px",
            height: "auto",
            backgroundColor: "#0C0057",
          }}
        >
          <table style={{ backgroundColor: "#0C0057", width: "100%" }}>
            <tbody>
              <tr>
                <td>
                  <ul style={{ listStyle: "none", padding: 0, marginRight: "13px" }}>
                    <li
                      style={{
                        width: "100%",
                        height: "50px",
                        backgroundColor: "#0C0057",
                        border: "1px dotted #FFFFFF",
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "15px",
                      }}
                    >
                      <PeopleIcon
                        sx={{
                          width: 20,
                          height: 20,
                          margin: 3,
                          color: "#FF00F3",
                        }}
                      />
                      <span>Courses: </span>
                      <span>0</span>
                    </li>
                    <li
                      style={{
                        width: "100%",
                        height: "50px",
                        backgroundColor: "#0C0057",
                        border: "1px dotted #FFFFFF",
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "15px",
                      }}
                    >
                      <HeadsetIcon
                        sx={{
                          width: 20,
                          height: 20,
                          margin: 3,
                          color: "#FF00F3",
                        }}
                      />
                      <span>Language: </span>
                      <span>English, Hindi</span>
                    </li>
                    <li
                      style={{
                        width: "100%",
                        height: "50px",
                        backgroundColor: "#0C0057",
                        border: "1px dotted #FFFFFF",
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "15px",
                      }}
                    >
                      <SettingsIcon
                        sx={{
                          width: 20,
                          height: 20,
                          margin: 3,
                          color: "#FF00F3",
                        }}
                      />
                      <span>Certificate: </span>
                      <span>Yes</span>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
      <FooterEnd />
    </div>
  );
};

export default EnrollPage;