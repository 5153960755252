import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import whyUsImg1 from "../assets/whyUsImg1.png";
import whyUsImg2 from "../assets/whyUsImg2.png";
import whyUsImg3 from "../assets/whyUsImg3.png";
import whyUsImg4 from "../assets/whyUsImg4.png";
import testimonialsimg from '../assets/testimonialsblock.png';
import "./App.css";

const WhyUs = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const whyUsRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
      if (whyUsRef.current) {
        const topPosition = whyUsRef.current.getBoundingClientRect().top;
        const isVisible = topPosition < window.innerHeight - 100;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const stats = [
    { value: '10000+', label: 'HAPPY STUDENTS', color: "#01a0c8" },
    { value: '20+', label: 'SKILLED TRAINERS', color: '#fa6590' },
    { value: '100+', label: 'LIVE TRAININGS', color: '#e49a29' },
    { value: '60+', label: 'VIDEO COURSES', color: '#5fcdff' },
  ];

  const whyUsItems = [
    { img: whyUsImg1, alt: "High Paying Skills", text: "High Paying Skills", bgColor: "#3a326b", className: "whyUsimg1" },
    { img: whyUsImg2, alt: "Valuable Courses", text: "Valuable Courses", bgColor: "#4f2765", className: "whyUsimg2" },
    { img: whyUsImg3, alt: "Affordable Pricing", text: "Affordable Pricing", bgColor: "#3a326b", className: "whyUsimg1" },
    { img: whyUsImg4, alt: "24/7 Online Support", text: "24/7 Online Support", bgColor: "#4f2765", className: "whyUsimg2" },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url(${testimonialsimg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        minHeight: "100vh",
        boxSizing: "border-box",
      }}
    >
      <div
        ref={whyUsRef}
        className={`${isVisible && scrollPosition > 0 ? "slide-up-animation" : ""} ${isVisible ? "visible" : ""}`}
        style={{
          color: "#DFDF1E",
          fontFamily: "Spartan, sans-serif",
          fontSize: isSmallScreen ? "24px" : "32px",
          fontWeight: "bold",
          textAlign: "center",
          margin: "40px 0 20px",
        }}
      >
        <span style={{ color: "#ffffff" }}>Why Choose </span>
        <span style={{ color: "#DFDF1E" }}>SkillsCraze</span>{" "}
        <span style={{ color: "#ffffff" }}>For Remote Learning</span>
      </div>
      <div
        ref={whyUsRef}
        className={`${isVisible && scrollPosition > 0 ? "slide-up-animation" : ""} ${isVisible ? "visible" : ""}`}
        style={{
          color: "#BABABA",
          fontFamily: "Poppins, sans-serif",
          fontSize: isSmallScreen ? "14px" : "16px",
          textAlign: "center",
          marginBottom: "40px",
        }}
      >
        Elevate Your Journey with Unparalleled Expertise, Experience a Transformation that Sets You Apart.
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "40px",
        }}
      >
        {whyUsItems.map((item, index) => (
          <div
            key={index}
            className={item.className}
            style={{
              backgroundColor: "#380e67",
              width: isSmallScreen ? "280px" : "220px",
              height: isSmallScreen ? "280px" : "220px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "20px",
              overflow: "hidden",
              border: "3px solid white",
              margin: "0 20px 0"
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                overflow: "hidden",
                backgroundColor: item.bgColor,
              }}
            >
              <img
                src={item.img}
                alt={item.alt}
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div
              className={`whyUsText${index % 2 + 1}`}
              style={{
                color: "#ffffff",
                fontFamily: "Spartan, sans-serif",
                fontSize: "18px",
                fontWeight: "bold",
                marginTop: "25px",
                textAlign: "center",
              }}
            >
              {item.text}
            </div>
          </div>
        ))}
      </div>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        style={{
          width: "100%",
          boxSizing: "border-box",
        }}
        p={2}
      >
        {stats.map((stat, index) => (
          <Box 
            key={index} 
            textAlign="center" 
            width={isSmallScreen ? "100%" : isMediumScreen ? "50%" : "25%"}
            mb={isSmallScreen || isMediumScreen ? 4 : 0}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color={stat.color}
            >
              {stat.value}
            </Typography>
            <Typography 
              variant="subtitle1" 
              color="white" 
              style={{ 
                paddingTop: '20px', 
                fontSize: '20px', 
                fontWeight: 'bold' 
              }}
            >
              {stat.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default WhyUs;