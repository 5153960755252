import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Collapse,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useParams, useLocation } from "react-router-dom";
import Footer from "./Footer";
import bgphoto from "../assets/bgphoto.png";

const Purchase = () => {
  const [showRazorPay, setShowRazorPay] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const courseDetails = {
    id,
    name: queryParams.get("name"),
    price: parseFloat(queryParams.get("price")),
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${bgphoto})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "800px",
          padding: { xs: 2, md: 4 },
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                color: "white",
                fontWeight: "bold",
                mb: 2,
                mt: { xs: 15, md: 15 },
              }}
            >
              Complete Your Order
            </Typography>
            <Paper
              sx={{
                backgroundColor: "#0c0057",
                borderRadius: 2,
                border: "1.5px solid #3d3972",
              }}
            >
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        sx={{ color: "white", fontWeight: "bold", borderBottom: "1.5px solid white" }}
                      >
                        Your Order Details
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ color: "white" }}>{courseDetails.name}</TableCell>
                      <TableCell align="right" sx={{ color: "white" }}>
                        ₹ {courseDetails.price}.00/-
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ color: "#FF00F3", fontWeight: "bold" }}>
                        Order Total
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ color: "#FF00F3", fontWeight: "bold" }}
                      >
                        ₹ {courseDetails.price}.00
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#0c0057",
                borderRadius: 2,
                border: "1.5px solid #3d3972",
                p: 3,
                mt: { xs: 2, md: 15 },
                mb: 2,
              }}
            >
              <Typography variant="h6" sx={{ color: "white", mb: 2 }}>
                Have a Coupon Code?
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  fullWidth
                  placeholder="Enter Coupon Code"
                  variant="outlined"
                  sx={{
                    mr: 1,
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#3d3972",
                      },
                      "&:hover fieldset": {
                        borderColor: "#3d3972",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3d3972",
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#FF00F3",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#D100C7",
                    },
                  }}
                >
                  Apply
                </Button>
              </Box>
            </Box>
            <Paper
              sx={{
                backgroundColor: "#0c0057",
                borderRadius: 2,
                border: "1.5px solid #3d3972",
                p: 3,
              }}
            >
              <Typography variant="h6" sx={{ color: "white", mb: 2 }}>
                Payment Modes
              </Typography>
              <Button
                fullWidth
                onClick={() => setShowRazorPay(!showRazorPay)}
                startIcon={showRazorPay ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                sx={{
                  justifyContent: "flex-start",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                Pay Using RazorPay Gateway
              </Button>
              <Collapse in={showRazorPay}>
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    backgroundColor: "white",
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    You can pay directly through RazorPay payment gateway using
                    UPI/Debit Card/Net Banking
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#FF00F3",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#D100C7",
                      },
                    }}
                  >
                    Place Order
                  </Button>
                </Box>
              </Collapse>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default Purchase;