import React, { useEffect, useState, useRef } from "react";
import { Typography, Card, CardContent, Grid } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useMediaQuery } from "@mui/material";
import { IconButton } from "@mui/material";
import "./App.css";
import bgphoto from "../assets/bgphoto.png"
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const Testimonials = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const whyUsRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
      const testimonialsSection = document.getElementById("testimonials");
      if (testimonialsSection && whyUsRef.current) {
        const topPosition = testimonialsSection.getBoundingClientRect().top;
        const bottomPosition = testimonialsSection.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
  
        const isVisible = (topPosition < windowHeight - 100 && bottomPosition > 0);
        setIsVisible(isVisible);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  const testimonial = [
    {
      feedback:
        "Hi, I am Smriti. I want to say that SkillsCraze is the best company in the whole Ed-tech industry. I learn so many digital marketing skills from SkillsCraze, now I monetize my skills & earn revenue from my social media only because of SkillsCraze. So thanks so much SkillsCraze & Prince Rajput Sir.",
      name: "Smriti",
      rating: 5,
    },
    {
      feedback:
        "Hi, I am Amrita. I have been using SkillsCraze for a while now and I am extremely satisfied with the quality of education and support provided. It has helped me enhance my skills and achieve my career goals.",
      name: "Amrita",
      rating: 4,
    },
  ];

  const handleNext = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === testimonial.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === 0 ? testimonial.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentTestimonialIndex(index);
  };

  return (
    <Grid id='testimonials'
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: `url(${bgphoto})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
        maxHeight: isSmallScreen ? "unset" : 700,
        color: "#FFFFFF",
        padding: isSmallScreen ? "10px" : "20px",
        textAlign: "center",
        position: "relative", 
        boxSizing: "border-box",
      }}
    >
      <Typography
        ref={whyUsRef}
        className={`${isVisible && scrollPosition > 0 ? "slide-in-from-right" : ""} ${isVisible ? "visible" : ""}`}
        variant="h5"
        gutterBottom
        style={{
          fontFamily: "Spartan, sans-serif ",
          textAlign: "center",
          fontSize: isSmallScreen ? "14px" : "17px",
          color: "#BABABA",
          fontWeight: "bold",
          paddingTop: "40px",
          paddingBottom: "10px",
        }}
      >
        TESTIMONIALS
      </Typography>
      <Typography
       ref={whyUsRef}
       className={`${isVisible && scrollPosition > 0 ? "slide-in-from-right" : ""} ${isVisible ? "visible" : ""}`}
        variant="h3"
        gutterBottom
        style={{
          color: "#FFFFFF",
          fontSize: isSmallScreen ? "24px" : "30px",
          fontFamily: "Spartan, sans-serif",
          margin: "0px 0px 4px",
          paddingBottom: "80px",
          fontWeight: "bold",
        }}
      >
        What Our Students Have To Say
      </Typography>
      <Card ref={whyUsRef}
        className={`${isVisible && scrollPosition > 0 ? "slide-in-from-right" : ""} ${isVisible ? "visible" : ""}`}
        sx={{
          maxWidth: isSmallScreen ? 420 : 740,
          animation: isVisible ? "slideIn 1s forwards" : "",
        }}
        style={{
          borderRadius: "12px",
          padding: isSmallScreen ? "15px 10px" : "20px 20px",
          backgroundColor: "#080667",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div style={{ position: "absolute", top: "50%", left: "20px", zIndex: 1 }}>
          <IconButton
            style={{ color: "#FFFFFF" }}
            onClick={handlePrev}
          >
            <ArrowBackIos />
          </IconButton>
        </div>
        <CardContent>
          <Typography
            variant="body1"
            gutterBottom
            style={{
              color: "#BABABA",
              fontSize: isSmallScreen ? "18px" : "20px",
              fontFamily: "Poppins, sans-serif",
              margin: "0px 0px 20px",
              paddingBottom: "80px",
            }}
          >
            {testimonial[currentTestimonialIndex].feedback}
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            fontWeight="bold"
            color={"#FFFFFF"}
          >
            {testimonial[currentTestimonialIndex].name}
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <StarIcon
              sx={{ color: "#f8b81f", fontSize: "19px", marginRight: "2px" }}
            />
            <StarIcon
              sx={{ color: "#f8b81f", fontSize: "19px", marginRight: "2px" }}
            />
            <StarIcon
              sx={{ color: "#f8b81f", fontSize: "19px", marginRight: "2px" }}
            />
            <StarIcon
              sx={{ color: "#f8b81f", fontSize: "19px", marginRight: "2px" }}
            />
            <StarIcon
              sx={{ color: "#f8b81f", fontSize: "19px", marginRight: "2px" }}
            />
          </div>
        </CardContent>
        <div style={{ position: "absolute", top: "50%", right: "20px", zIndex: 1 }}>
          <IconButton
            style={{ color: "#FFFFFF" }}
            onClick={handleNext}
          >
            <ArrowForwardIos />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "10px", 
          }}
        >
          {testimonial.map((_, index) => (
            <span
              key={index}
              onClick={() => handleDotClick(index)}
              style={{
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                backgroundColor:
                  currentTestimonialIndex === index ? "#ffffff" : "#888888",
                margin: "0 5px",
                cursor: "pointer",
              }}
            ></span>
          ))}
        </div>
      </Card>
      <div style={{ height: isSmallScreen ? "10px" : "27px" }}></div>
    </Grid>
  );
};

export default Testimonials;
