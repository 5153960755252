import React from "react";
import Footer from "./Footer";
import FooterEnd from "./FooterEnd";
import "./App.css";
import bgphoto from '../assets/bgphoto.png'

const Register = () => {
  const inputFields = [
    { title: "Affiliation Code", placeholder: "Your Affiliate Code" },
    { title: "Name", placeholder: "Name" },
    { title: "Email Address", placeholder: "ABC@gmail.com" },
    { title: "Phone Number", placeholder: "+91 1234567890" },
    { title: "State", placeholder: "Enter State" },
    { title: "Password", placeholder: "Create Password" },
    { title: "Confirm Password", placeholder: "Confirm Password" },
  ];

  return (
    <div
      style={{
        backgroundImage: `url(${bgphoto})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        minHeight: "100vh",
        overflowX: "hidden",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "120px 20px 0",
        maxWidth: "100vw",
      }}
      className="register-container"
    >
      <div
        style={{
          width: "100%",
          maxWidth: "1012.6px",
          color: "#FFFFFF",
          fontSize: "30px",
          fontFamily: "poppins, sans-serif",
          textAlign: "center",
          marginBottom: "8px",
          padding: "20px",
          fontWeight: "bold",
        }}
      >
        Create SkillsCraze Account
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: "1012.6px",
          color: "#FFFFFF",
          fontSize: "15px",
          fontFamily: "open-sans, sans-serif",
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: "bold",
        }}
      >
        If you don't have an account you must enroll at least one package first
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: "807px",
          backgroundColor: "#0C0057",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "80px",
        }}
      >
        <form
          style={{
            width: "100%",
            maxWidth: "747px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          <div style={{ marginBottom: "20px", width: "100%" }}>
            <div
              style={{
                color: "#FFFFFF",
                fontSize: "15px",
                fontFamily: "poppins, sans-serif",
                fontWeight: "bold",
                marginBottom: "6px",
              }}
            >
              {inputFields[0].title}
            </div>
            <input
              type="text"
              style={{
                width: "100%",
                height: "55px",
                color: "#FFFFFF",
                backgroundColor: "#0b0517",
                border: "none",
                borderRadius: "8px",
                padding: "0 0 0 12px",
              }}
              placeholder={inputFields[0].placeholder}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {inputFields.slice(1).map((field, index) => (
              <div
                key={index}
                style={{
                  width: "100%",
                  maxWidth: "361.5px",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    color: "#FFFFFF",
                    fontSize: "15px",
                    fontFamily: "poppins, sans-serif",
                    fontWeight: "bold",
                    marginBottom: "6px",
                  }}
                >
                  {field.title}
                </div>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "51px",
                    color: "#FFFFFF",
                    fontWeight: 'bold',
                    backgroundColor: "#0b0517",
                    border: "none",
                    borderRadius: "8px",
                    padding: "0 0 0 12px",
                  }}
                  placeholder={field.placeholder}
                />
              </div>
            ))}
          </div>
        </form>
        <div style={{ marginBottom: "20px" }}>
          <p style={{ margin: "0", padding: "0" }}>
            <input
              type="checkbox"
              required
              style={{ width: "15px", height: "15px", marginRight: "5px" }}
            />
            <span
              style={{
                color: "#FFFFFF",
                fontFamily: "open-sans, sans-serif",
                cursor: "pointer",
              }}
            >
              I agree to the{" "}
              <span style={{ color: "#FF00F3", fontWeight: "bold" }}>
                Privacy Policy
              </span>{" "}
              and{" "}
              <span style={{ color: "#FF00F3", fontWeight: "bold" }}>
                Terms & Condition{" "}
              </span>
              .
            </span>
          </p>
        </div>
        <button
          className="registerButton"
          style={{
            width: "100%",
            height: "51px",
            color: "#FFFFFF",
            fontSize: "15px",
            fontFamily: "poppins, sans-serif",
            fontWeight: "bold",
            borderRadius: "8px",
            border: "none",
          }}
        >
          Register Account
        </button>
      </div>
      <Footer />
      <FooterEnd />
    </div>
  );
};

export default Register;