import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import testimonialsimg from "../assets/testimonialsblock.png";
import logo from "../assets/leads.png";

const leadData = [
  {
    title: "BULK LEADS",
    price: "100RS",
    description: "100 LEADS ORDER COMPLETED IN 12 HOURS.",
    image: logo,
  },
  {
    title: "NORMAL LEADS",
    price: "100RS",
    description: "80 LEADS ORDER COMPLETED IN 12 HOURS.",
    image: logo,
  },
  {
    title: "QUALITY LEADS",
    price: "100RS",
    description: "40 LEADS ORDER COMPLETED IN 12 HOURS.",
    image: logo,
  },
  {
    title: "CONVERTING LEADS",
    price: "300RS",
    description: "30 LEADS ORDER COMPLETED IN 8 HOURS.",
    image: logo,
  },
  {
    title: "PREMIUM LEADS",
    price: "500RS",
    description: "70 LEADS ORDER COMPLETED IN 6 HOURS.",
    image: logo,
  },
  {
    title: "PREMIUM LEADS",
    price: "1000RS",
    description: "80 LEADS ORDER COMPLETED IN 4 HOURS.",
    image: logo,
  },
];

const Leads = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const numItemsToShow = isSmallScreen ? 1 : 3;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % leadData.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + leadData.length) % leadData.length);
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [isSmallScreen]);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${testimonialsimg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        pt: { xs: 4, md: 8 },
        pb: { xs: 4, md: 8 },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          color: "#FFFFFF",
          fontFamily: "serif",
          fontSize: { xs: 32, sm: 40, md: 56 },
          fontWeight: "normal",
          mb: { xs: 4, md: 6 },
          textAlign: "center",
        }}
      >
        LEADS
      </Typography>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px',
          textAlign: 'center',
          mb: { xs: 4, md: 6 },
          px: { xs: 2, sm: 4, md: 2 },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: '#FFFFFF',
            fontFamily: 'Vidaloka',
            fontSize: { xs: 16, sm: 20, md: 24 },
            fontWeight: 'normal',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              bottom: -8,
              left: '50%',
              transform: 'translateX(-50%)',
              width: { xs: '80%', sm: '60%', md: '50%' },
              height: 2,
              backgroundColor: '#FFFFFF',
            },
          }}
        >
          Fresh and genuine leads available for network marketing and affiliate marketing
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', width: '100%', maxWidth: '1200px', overflow: 'hidden', px:2 }}>
        <IconButton 
          onClick={handlePrev} 
          sx={{
            position: 'absolute', 
            left: 10, 
            top: '50%', 
            transform: 'translateY(-50%)', 
            zIndex: 1, 
            color: 'white',
            backgroundColor: 'rgba(186, 17, 229, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(186, 17, 229, 0.8)',
            },
            boxShadow: '0 0 10px rgba(186, 17, 229, 0.5)',
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton 
          onClick={handleNext} 
          sx={{ 
            position: 'absolute', 
            right: 10, 
            top: '50%', 
            transform: 'translateY(-50%)', 
            zIndex: 1, 
            color: 'white',
            backgroundColor: 'rgba(18, 38, 222, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(18, 38, 222, 0.8)',
            },
            boxShadow: '0 0 10px rgba(18, 38, 222, 0.5)',
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            transition: 'transform 0.3s ease-in-out',
            transform: `translateX(-${currentIndex * (100 / numItemsToShow)}%)`,
            '& > *': {
              flexShrink: 0,
              width: `${100 / numItemsToShow}%`,
            },
          }}
        >
          {leadData.map((lead, index) => (
            <Box
              key={index}
              sx={{
                height: isSmallScreen ? "auto" : "501px",
                position: "relative",
                // background: "linear-gradient(75deg, #BA11E5, #1226DE)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
        width: `${100 / numItemsToShow}%`,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#220c45",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: isSmallScreen ? "80%" : "205px",
                    height: isSmallScreen ? "auto" : "162px",
                    margin: "10px auto 0",
                    borderRadius: "8px",
                
                    // background: "linear-gradient(-75deg, #E736AA 22%, #1427D6 77%)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={lead.image}
                    sx={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </Box>
                <Box 
                  sx={{ 
                    flex: 1,
                    mt: 2, 
                    mx: "20px",
                    backgroundColor: "#2D0065",
                    borderRadius: "8px",
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Vidaloka",
                      fontSize: isSmallScreen ? "20px" : "24px",
                      mb: 1,
                      textAlign:'center',
                      fontWeight:'bold'
                    }}
                  >
                    {lead.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Vidaloka",
                      fontSize: isSmallScreen ? "16px" : "18px",
                      mb: 1,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    Price: {lead.price}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Vidaloka",
                      fontSize: isSmallScreen ? "14px" : "16px",
                      mb: 2,
                      flex: 1,
                      overflowY: "auto",
                    }}
                  >
                    {lead.description}
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                    <Box
                      sx={{
                        width: isSmallScreen ? "80%" : "199px",
                        height: isSmallScreen ? "auto" : "78px",
                        background: "linear-gradient(20deg, #BA11E5 , #1227DE)",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: isSmallScreen ? "2px" : "3px",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          height: "100%",
                          color: "#FFFFFF",
                          backgroundColor: "#181036",
                          borderRadius: "6px",
                          "&:hover": {
                            backgroundColor: "#2a1d63",
                          },
                        }}
                      >
                        Buy now
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Leads;